import {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import styled from "@emotion/styled";
import {useMounted} from "@rw/react_utils";
import {includes, isEmpty, map} from "lodash";
import * as lscache from "lscache";
import {bindActionCreators, Dispatch} from "redux";

import {appLink, parseOfferSlugToObject} from "../../../../../packages/utils/cars_routes";
import {loadFavouritesToStore, setFavouriteCar} from "../../app/actions/load_local_storage_favourites_to_store";
import {FAVOURITES_LS_NAME} from "../../app/constants/storage_keys";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {RequestState} from "../../app/utils/request_response_utils/factories/reduce_request_state";
import {ICarListOfferResponse} from "../../meta_data/reducers/meta_data_reducer";
import {CarBox} from "../../offer/detail/components/car_box/CarBox";
import {ViewType} from "../../tracking/view_type/view_type";
import {fetchFavouriteCarList, removeFetchedFavourite} from "../actions/fetch_favourite_car_list";

interface IActionProps {
    fetchFavouriteCarList: typeof fetchFavouriteCarList;
    setFavouriteCar: typeof setFavouriteCar;
    removeFetchedFavourite: typeof removeFetchedFavourite;
}
interface IProps extends IStateProps, IActionProps {}
interface IStateProps {
    favourites: number[];
    favouriteCarList: ICarListOfferResponse[];
    favouriteCarListRequest: RequestState;
    viewType: ViewType | null;
}

const FavouriteCarsDynamicC = (props: IProps) => {
    const isMounted = useMounted();

    const [applicationOfferData, setApplicationOfferData] = useState<{car: ICarListOfferResponse} | null>(null);

    useEffect(() => {
        if (!isEmpty(props.favourites)) {
            props.fetchFavouriteCarList();
        }
    }, []);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.favouriteCarListRequest === RequestState.Success && props.favourites.length !== props.favouriteCarList.length) {
            const activeCars = props.favouriteCarList.reduce((acc, current) => {
                return [...acc, current.id];
            }, [] as number[]);
            lscache.set(FAVOURITES_LS_NAME, activeCars);
            dispatch(loadFavouritesToStore(activeCars));
        }
    }, [props.favouriteCarListRequest]);

    if (props.favouriteCarListRequest === RequestState.Waiting || !isMounted) {
        return <LoaderWrapper>Loading...</LoaderWrapper>;
    }

    if (
        (isEmpty(props.favourites) && isEmpty(props.favouriteCarList)) ||
        (props.favouriteCarListRequest === RequestState.Success && isEmpty(props.favouriteCarList))
    ) {
        return (
            <EmptyListWrapper>
                <EmptyText style={{padding: "0 2rem"}}>
                    Nie masz dodanych jeszcze
                    <br />
                    ulubionych samochodów.
                </EmptyText>
            </EmptyListWrapper>
        );
    }

    const onOfferClick = (e: React.MouseEvent<HTMLElement>, car: {slug: string}) => {
        () => (window.location.href = appLink.fullOffer.detail.base(parseOfferSlugToObject(car.slug)));
    };
    const onDeleteFav = (id: number) => {
        props.setFavouriteCar(id);
        dispatch(removeFetchedFavourite(id));
    };

    return (
        <div>
            <HeaderTitle>
                <HeaderTitleHeading>Ulubione samochody</HeaderTitleHeading>
            </HeaderTitle>
            <ListingWrapper>
                <CarGridContainer>
                    <Container>
                        <Grid>
                            {map(props.favouriteCarList, (car) => {
                                const isFavourite = includes(props.favourites, car.id);

                                return (
                                    <CarBox
                                        key={car.id}
                                        id={car.id}
                                        brand={car.acf.make}
                                        model={car.acf.model}
                                        year={Number(car.acf.year)}
                                        mileage={Number(car.acf.millage)}
                                        price={Number(car.acf.price)}
                                        image={car.acf.gallery[0].sizes.medium}
                                        slug={car.slug}
                                        onDeleteFav={onDeleteFav}
                                    />
                                );
                            })}
                        </Grid>
                    </Container>
                </CarGridContainer>
            </ListingWrapper>{" "}
        </div>
    );
};

function mapStateToProps(state: IStore): IStateProps {
    return {
        favourites: state.favourites.favourites,
        favouriteCarList: state.favourites.favouriteCarList,
        favouriteCarListRequest: state.favourites.favouriteCarListRequest,
        viewType: state.viewType.current
    };
}

function mapActionsToProps(dispatch: Dispatch) {
    return bindActionCreators(
        {
            fetchFavouriteCarList,
            setFavouriteCar,
            removeFetchedFavourite
        },
        dispatch
    );
}

export const FavouriteCarsDynamic = connect(mapStateToProps, mapActionsToProps)(FavouriteCarsDynamicC);

export const EmptyListWrapper = styled.div`
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.gray_very_bright};
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        height: calc(100vh - 50px);
    }
`;

//Listing
const ListingWrapper = styled.section`
    padding: 1rem 0 0 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 0;
    width: 100%;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.screen_md}) {
        justify-content: center;
    }
`;

const LoaderWrapper = styled.div`
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.gray_very_bright};
    @media screen and (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        height: calc(100vh - 50px);
    }
`;
const HeaderTitleHeading = styled.h1`
    font-size: 2em;
    margin: 4rem 0 0 0;
    font-weight: 500;
    color: ${(props) => props.theme.colors.gray_dark};
`;

const HeaderTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const EmptyText = styled.h2`
    text-align: center;
    color: ${(props) => props.theme.colors.gray_brighter};
    font-size: 2.6rem;
    font-weight: 500;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
`;

const CarGridContainer = styled.section`
    padding: 20px;
    background-color: #f9f9f9;
    margin: 40px auto;
`;
export const Container = styled.div`
    margin: 0 auto;
`;
