import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {IStore} from "../../app/reducers/hybrid_reducer";

const validateFile = (file: File) => {
    const errors: string[] = [];
    if (file.size > 5000000) {
        errors.push("Plik jest zbyt duży. Maksymalny rozmiar to 5MB.");
    }
    if (!/image\/(jpg|jpeg|png|gif)$/i.test(file.type)) {
        errors.push("Plik musi być w formacie JPG, JPEG, PNG lub GIF.");
    }
    return errors;
};

interface UploadResponse {
    url: string;
    filename: string;
}

const UploadImageToS3WithNativeSdk = () => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [filePreviews, setFilePreviews] = useState<string[]>([]); // Lista podglądów zdjęć

    const [uploadProgress, setUploadProgress] = useState<number[]>([]);
    const {values, setFieldValue, submitForm} = useFormikContext<{fileKeys: string[]}>();
    const [fileKeys, setFileKeys] = useState<string[]>([]); // Lista kluczy plików na S3

    const userData = useSelector((store: IStore) => store.dashboard.userData);
    const [deletedFile, setDeletedFile] = useState<string | null>(null); // Stan do śledzenia usuniętego pliku
    // Pobierz obrazy z S3 na podstawie fileKeys

    useEffect(() => {
        if (values.fileKeys.length > 0) {
            const fileKeysArray = values.fileKeys;

            setFileKeys(fileKeysArray); // Ustawienie fileKeys

            setFieldValue("fileKeys", fileKeysArray);
        }
    }, [values.fileKeys]);

    const uploadFile = async (file: File) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("userID", userData.data.user_id?.toString() || "");
        try {
            const response = await fetch("https://rw-web-api.eu/wp-json/wp/v2/upload_gallery_to_s3/", {
                method: "POST",
                body: formData
            });

            const data: UploadResponse = await response.json();

            if (response.ok) {
                setFileKeys((prevKeys) => {
                    const updatedKeys = [...prevKeys, data.filename];

                    setFieldValue("fileKeys", updatedKeys);
                    return updatedKeys;
                });
                return true; // Return success status
            } else {
                console.error("Error uploading file:", data);
                return false;
            }
        } catch (error) {
            console.warn("err on uploadToS3", error);
            return false;
        }
    };

    const handleRemoveImage = async (key: string) => {
        const formData = new FormData();
        formData.append("key", key);
        try {
            const response = await fetch("https://rw-web-api.eu/wp-json/wp/v2/delete_from_s3/", {
                method: "POST",
                body: formData
            });

            const data: any = await response.json();
            if (response.ok) {
                setDeletedFile(key);

                // Usuwanie pliku z lokalnego stanu
                setSelectedFiles((prevFiles) => prevFiles.filter((file) => `${userData.data.user_id}_${file.name}` !== key));
                setFilePreviews((prevPreviews) => prevPreviews.filter((_, index) => `${userData.data.user_id}_${selectedFiles[index].name}` !== key));
                setUploadProgress((prevProgress) => prevProgress.filter((_, index) => `${userData.data.user_id}_${selectedFiles[index].name}` !== key));
                setFileKeys((prevKeys) => prevKeys.filter((fileKey) => fileKey !== key));
            } else {
                console.error("Error deleting file:", data);
            }
        } catch (error) {
            console.warn("err on delete from s3 front ", error);
        }
    };

    useEffect(() => {
        // when fileKeys has change update fileKeys in Formik
        setFieldValue("fileKeys", fileKeys);
    }, [fileKeys, deletedFile, setFieldValue, submitForm]);

    const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const selectedFileArray = Array.from(files);
            setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...selectedFileArray]);

            const previews = selectedFileArray.map((file) => {
                const reader = new FileReader();
                return new Promise<string>((resolve) => {
                    reader.onloadend = () => resolve(reader.result as string);
                    reader.readAsDataURL(file); // Tworzy URL base64 dla obrazu
                });
            });

            // Po uzyskaniu wszystkich podglądów ustawiamy je w stanie
            Promise.all(previews).then((previews) => {
                setFilePreviews((prevFiles) => {
                    return [...prevFiles, ...previews];
                });
            });

            // Zresetowanie postępu ładowania przed rozpoczęciem nowego ładowania
            setUploadProgress(new Array(selectedFileArray.length).fill(0));

            const fileArray = Array.from(files);
            const uploadResults = await Promise.all(fileArray.map((file) => uploadFile(file)));

            // TODO: handle incorrect uploads
            uploadResults.forEach((result, index) => {
                if (result) {
                    //console.log(`File ${fileArray[index].name} uploaded successfully.`);
                } else {
                    // console.warn(`File ${fileArray[index].name} failed to upload.`);
                }
            });
        }
    };

    return (
        <div>
            <div>
                <input type="file" multiple onChange={handleFileInput} />
            </div>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {selectedFiles.map((image, index) => {
                    const imageKey = `${userData.data.user_id}_${image.name}`;
                    const fileErrors = validateFile(image);
                    return (
                        <div key={image.name} style={{margin: "10px"}}>
                            <div>
                                <img
                                    src={filePreviews[index]} // Wyświetlanie podglądu zdjęcia
                                    alt="Preview"
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                        marginBottom: "10px"
                                    }}
                                />
                                {uploadProgress[index] > 0 && (
                                    <div style={{width: "100%", marginTop: "5px"}}>
                                        <progress
                                            value={uploadProgress[index]}
                                            max={100}
                                            style={{
                                                width: "100%",
                                                height: "10px",
                                                borderRadius: "5px",
                                                backgroundColor: "#f3f3f3",
                                                appearance: "none"
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                            {fileErrors.length > 0 && (
                                <div style={{color: "red", marginTop: "5px"}}>
                                    {fileErrors.map((error, idx) => (
                                        <p key={idx}>{error}</p>
                                    ))}
                                </div>
                            )}
                            <button
                                type="button"
                                onClick={() => handleRemoveImage(imageKey)} // Usuwanie zdjęcia po Key
                                style={{
                                    display: "block",
                                    marginTop: "5px",
                                    backgroundColor: "red",
                                    color: "white"
                                }}
                            >
                                Usuń
                            </button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default UploadImageToS3WithNativeSdk;
