import * as React from "react";

import {CarGrid} from "../offer/detail/components/car_box/CarGrid";
import {SearchBar} from "../search/SearchBar";
import {CarBrandsSection} from "./components/CarBrandsSection";
import {HeroSection} from "./components/HeroSection";
import {SeasonalOffer} from "./components/SeasonalOffer";

export const HomepageDynamic = () => {
    return (
        <>
            <HeroSection />
            <SearchBar />
            <CarBrandsSection />
            <SeasonalOffer />
            <CarGrid />
        </>
    );
};
