import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {appLink} from "@rw/cars_routes";
import {carsTheme} from "@rw/themes";

import {addOffer} from "../../navigation/NavigationMain";

import heroImg from "../../styles/assets/background/spring_offer.webp";

export const SeasonalOffer = () => {
    return (
        <Container>
            <section css={heroWrapper}>
                <div css={offerContent}>
                    <h2 css={offerHeading}>
                        Poczuj wiosnę i zmień smochód na inny z zakupauto.pl
                        <br />
                        dodaj swoją ofertę <span css={primaryColor}>za darmo!</span>
                    </h2>
                    <a css={[addOffer, offerBordered]} href={appLink.admin.login}>
                        Dodaj ofertę
                    </a>
                </div>
            </section>
        </Container>
    );
};

export const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
`;

const primaryColor = css`
    color: ${carsTheme.colors.brand_primary};
`;

const offerBordered = css`
    border: 2px solid #fff;
    height: 42px;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    position: relative;
    width: fit-content;
    margin: 20px auto;

    background: transparent;
    font-weight: 600;

    font-size: 16px;
    color: ${carsTheme.colors.brand_primary};

    border: 2px solid ${carsTheme.colors.brand_primary};
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        cursor: pointer;
        color: #ffffff;
        background: ${carsTheme.colors.brand_primary};
    }
`;

const offerContent = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    margin: 210px auto;
    width: calc(100% - 40px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    @media (min-width: ${theme.breakpoints.screen_xxs}) {
        width: fit-content;
    }
    @media (min-width: ${theme.breakpoints.screen_sm}) {
        margin: auto 20px;
        box-shadow: none;
    }
    @media (min-width: ${theme.breakpoints.screen_md}) {
        margin: auto 0;
    }
`;

const offerHeading = css`
    max-width: 320px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: auto;
`;

const heroWrapper = (theme: Theme) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 300px;
    padding: 0 0 30rem 0;
    z-index: 1;
    background-image: url("${heroImg}");
    background-repeat: no-repeat;
    background-position: center, right;
    background-size: 100%;
    width: 100%;
    margin-bottom: 210px;
    @media (min-width: ${theme.breakpoints.screen_sm}) {
        margin-bottom: 0;
        padding: 5rem 0 2rem;
        height: 450px;
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        padding: 3rem 2rem 2rem;
        height: 400px;
    }
`;
