import {useState} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Button} from "@rw/button";
import {TextEditor} from "@rw/editor";
import {carsTheme} from "@rw/themes";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

import {fetchCitiesByStateName} from "../../app/api/fetch_cities";
import {Alert, AlertType} from "../../app/components/alert/Alert";
import {useAlert} from "../../app/components/alert/useAlert";
import {apiLink} from "../../app/routes/api_link";
import {getStateNameBySlug, Voivodeship, voivodeshipNames} from "../../app/types/locationTypes";
import {postRequest} from "../../app/utils/request_response_utils/request";
import UploadImageToS3WithNativeSdk from "../../aws/s3/upload_to.s3";
import {AdditionalFeaturesPartForm} from "../components/Additionalfeatures";
import {AppearancePartForm} from "../components/Appearance";
import {ComfortAndConveniencePartForm} from "../components/ComfortAndConvenience";
import {MultimediaPartForm} from "../components/Multimedia";
import {SaftyPartForm} from "../components/SaftyOpt";
import {carBodyTypes, engineTypes} from "./options/select_options";

export interface IGalleryObjType {
    id: string;
    file: File;
    preview: string;
}

interface AddCarFormValues {
    title: string;
    content: string;
    expiry: number;
    make: string;
    model: string;
    year: string;
    millage: string;
    engine: string;
    engine_size: string;
    power: number | null;
    price: string;
    gearbox: string;
    color: string;
    voivodeship: string | null;
    city: string | null;
    fileKeys: string[];
    mainImage?: IGalleryObjType;
    //
    registry_date: string;
    seller_name: string;
    phone_number: string;
    version: string;
    generation: string;
    doors_counts: number;
    seats_counts: number;
    vin_id: string;
    body_type: string;
    damaged: boolean;
    imported: boolean;
    multimedia: string[];
    comfort_and_convenience: string[];
    appearance_and_materials: string[];
    safety: string[];
    additional_features: string[];
}
//TODO change expiry to lower when more data available
const addCarInitialValues: AddCarFormValues = {
    title: "",
    content: "",
    expiry: 30,
    make: "",
    model: "",
    year: "",
    millage: "",
    engine: "",
    engine_size: "",
    power: 0,
    price: "",
    gearbox: "manualna",
    color: "",
    voivodeship: "",
    city: "",
    fileKeys: [],
    mainImage: undefined,
    registry_date: "",
    seller_name: "",
    phone_number: "",
    version: "",
    generation: "",
    doors_counts: 0,
    seats_counts: 0,
    vin_id: "",
    body_type: "",
    damaged: false,
    imported: false,
    multimedia: [],
    comfort_and_convenience: [],
    appearance_and_materials: [],
    safety: [],
    additional_features: []
};

const addCarValidationSchema = Yup.object({
    title: Yup.string().required("Tytuł jest wymagany"),
    voivodeship: Yup.string().required("Wybierz województwo"),
    phone_number: Yup.string()
        .matches(/^[0-9]{9,15}$/, "Numer telefonu musi zawierać od 9 do 15 cyfr")
        .required("Numer telefonu jest wymagany"),
    city: Yup.string().required("Miasto jest wymagane"),
    price: Yup.number().typeError("Cena musi być liczbą").positive("Cena musi być większa niż 0").required("Cena jest wymagana"),
    year: Yup.number()
        .typeError("Rok musi być liczbą")
        .integer("Rok musi być liczbą całkowitą")
        .min(1900, "Rok musi być większy niż 1900")
        .max(new Date().getFullYear(), "Rok nie może być większy niż obecny")
        .required("Rok produkcji jest wymagany"),
    millage: Yup.number().typeError("Przebieg musi być liczbą").positive("Przebieg musi być większy niż 0").required("Przebieg jest wymagany"),
    engine_size: Yup.number()
        .typeError("Pojemność silnika musi być liczbą")
        .positive("Pojemność musi być większa niż 0")
        .required("Pojemność silnika jest wymagana"),
    power: Yup.number().typeError("Moc silnika musi być liczbą").positive("Moc musi być większa niż 0").required("Moc silnika jest wymagana"),
    registry_date: Yup.string().required("Data rejestracji jest wymagana"),
    seller_name: Yup.string().required("Imię i nazwisko sprzedawcy są wymagane"),
    doors_counts: Yup.number()
        .typeError("Liczba drzwi musi być liczbą")
        .integer("Liczba drzwi musi być liczbą całkowitą")
        .required("Liczba drzwi jest wymagana"),
    seats_counts: Yup.number()
        .typeError("Liczba miejsc musi być liczbą")
        .integer("Liczba miejsc musi być liczbą całkowitą")
        .required("Liczba miejsc jest wymagana"),
    content: Yup.string().min(10, "Opis musi mieć co najmniej 10 znaków").required("Opis jest wymagany"),
    fileKeys: Yup.array().of(Yup.string()).min(1, "Dodaj przynajmniej jedno zdjęcie").required("Zdjęcie jest wymagane"),
    make: Yup.string().required("Marka jest wymagana"),
    model: Yup.string().required("Model jest wymagany"),
    body_type: Yup.string().required("Typ nadwozia jest wymagany"),
    engine: Yup.string().required("Silnik jest wymagany"),
    gearbox: Yup.string().required("Skrzynia biegów jest wymagana"),
    color: Yup.string().required("Kolor jest wymagany"),
    expiry: Yup.string().required("Czas oferty jest wymagany")
    // files: Yup.array()
    //     .of(
    //         Yup.object().shape({
    //             name: Yup.string().required("Nazwa pliku jest wymagana"),
    //             size: Yup.number()
    //                 .max(5000000, "Plik jest zbyt duży. Maksymalny rozmiar to 5MB.") // Maksymalny rozmiar pliku 5MB
    //                 .required("Rozmiar pliku jest wymagany"),
    //             type: Yup.string()
    //                 .matches(/^image\/(jpg|jpeg|png|gif)$/i, "Plik musi być w formacie JPG, JPEG, PNG lub GIF")
    //                 .required("Typ pliku jest wymagany")
    //         })
    //     )
    //     .min(1, "Proszę wybrać przynajmniej jeden plik")
    //     .required("Pliki są wymagane")
});

interface IAddCarProps {
    user: {
        id: number | null;
    };
    nonce: string | null;
}

export const AddCar = (props: IAddCarProps) => {
    const {alert, showAlert} = useAlert();

    const [cities, setCities] = useState<string[]>([]);
    const [isCityDisabled, setIsCityDisabled] = useState(true);
    const formatContentForEditor = (content: string): string => {
        return content.replace(/\r\n|\n|\r/g, "<br />");
    };
    const handleVoivodeshipChange = async (slug: string) => {
        setIsCityDisabled(true);
        const stateName = getStateNameBySlug(slug);
        if (stateName) {
            const fetchedCities = await fetchCitiesByStateName(stateName);
            setCities(fetchedCities);
            setIsCityDisabled(false);
        } else {
            setCities([]);
        }
    };

    const handleSubmit = async (values: AddCarFormValues, {resetForm}: {resetForm: () => void}) => {
        try {
            const data = {
                author_id: props.user.id || 1,
                title: values.title,
                content: values.content,
                expiry: values.expiry,
                make: values.make,
                model: values.model,
                year: values.year,
                millage: parseInt(values.millage.replace(" ", "")),
                engine: values.engine,
                engine_size: parseInt(values.engine_size.replace(" ", "")),
                power: values.power,
                price: values.price,
                gearbox: values.gearbox,
                color: values.color,
                voivodeship: values.voivodeship,
                city: values.city,
                fileKeys: values.fileKeys.join("|"),
                registry_date: values.registry_date,
                seller_name: values.seller_name,
                phone_number: values.phone_number,
                version: values.version,
                generation: values.generation,
                doors_counts: values.doors_counts,
                seats_counts: values.seats_counts,
                vin_id: values.vin_id,
                body_type: values.body_type,
                damaged: values.damaged,
                imported: values.imported,
                multimedia: values.multimedia,
                additional_features: values.additional_features,
                appearance_and_materials: values.appearance_and_materials,
                comfort_and_convenience: values.comfort_and_convenience,
                safety: values.safety
            };

            const url = apiLink.addCar({})(data);

            return postRequest({}, url, data)
                .then((res: any) => {
                    showAlert(`Ogłoszenie dodano - zostanie wyświetlone po akceptacji administratora.`, AlertType.success, 5000);
                    resetForm();
                    return res;
                })
                .catch((err) => {
                    console.warn("err on reset", err);
                });
        } catch (err) {
            console.error("Błąd podczas dodawania ogłoszenia:", err);
            showAlert(`Błąd podczas dodawania ogłoszenia`, AlertType.danger, 5000);
        }
    };

    return (
        <>
            <Formik<AddCarFormValues> initialValues={addCarInitialValues} validationSchema={addCarValidationSchema} onSubmit={handleSubmit}>
                {({isSubmitting, values, setFieldValue, handleChange}) => {
                    return (
                        <FormWrapper>
                            <h2 css={{margin: "2rem 0"}}>Dodaj Ogłoszenie</h2>
                            <StyledForm>
                                <Field css={inputStyle} name="title" type="text" placeholder="Tytuł" />
                                <ErrorMessage name="title" component="div" style={{color: "red"}} />

                                <TextEditor
                                    id="content"
                                    name="content"
                                    value={values.content}
                                    onChange={(name, value) => setFieldValue("content", value)}
                                    maxLength={2000}
                                />
                                <ErrorMessage name="content" component="div" style={{color: "red"}} />

                                {/*<Field css={inputStyle} name="content" as="textarea" rows={6} placeholder="Opis" />*/}
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="make" type="text" placeholder="Marka" />
                                    <ErrorMessage name="make" component="div" style={{color: "red"}} />

                                    <Field css={inputStyle} name="model" type="text" placeholder="Model" />
                                    <ErrorMessage name="model" component="div" style={{color: "red"}} />

                                    <Field as="select" css={inputStyle} name="body_type" placeholder="Typ nadwozia">
                                        <option value="" disabled>
                                            Typ nadwozia
                                        </option>
                                        {carBodyTypes.map((bodyType) => (
                                            <option key={bodyType.value} value={bodyType.value}>
                                                {bodyType.label}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="body_type" component="div" style={{color: "red"}} />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="year" type="text" placeholder="Rocznik" />
                                    <ErrorMessage name="year" component="div" style={{color: "red"}} />

                                    <Field css={inputStyle} name="millage" type="text" placeholder="Przebieg" />
                                    <ErrorMessage name="millage" component="div" style={{color: "red"}} />

                                    <Field css={inputStyle} name="power" type="number" placeholder="Moc (KM)" value={values.power === 0 ? "" : values.power} />
                                    <ErrorMessage name="power" component="div" style={{color: "red"}} />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field as="select" css={inputStyle} name="engine" placeholder="Silnik">
                                        <option value="" disabled>
                                            Wybierz silnik
                                        </option>
                                        {engineTypes.map((engine) => (
                                            <option key={engine.value} value={engine.value}>
                                                {engine.label}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="engine" component="div" style={{color: "red"}} />

                                    <Field css={inputStyle} name="engine_size" type="text" placeholder="Pojemność silnika" />
                                    <ErrorMessage name="engine_size" component="div" style={{color: "red"}} />

                                    <Field as="select" css={inputStyle} name="gearbox" placeholder="Skrzynia biegów">
                                        <ErrorMessage name="gearbox" component="div" style={{color: "red"}} />

                                        <option value="" disabled>
                                            Skrzynia biegów
                                        </option>
                                        <option value="manualna">Manualna</option>
                                        <option value="automatyczna">Automatyczna</option>
                                    </Field>
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field
                                        as="select"
                                        css={inputStyle}
                                        name="voivodeship"
                                        placeholder="Województwo"
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            handleChange(e); // Aktualizuj Formika
                                            handleVoivodeshipChange(e.target.value); // Zajmij się logiką zmiany województwa
                                            setFieldValue("city", ""); // Resetuj pole `city`
                                        }}
                                    >
                                        <option value="" disabled>
                                            Wybierz województwo
                                        </option>
                                        {Object.entries(Voivodeship).map(([key, value]) => (
                                            <option key={key} value={key.toLowerCase()}>
                                                {voivodeshipNames[value]}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="voivodeship" component="div" style={{color: "red"}} />

                                    <Field as="select" css={inputStyle} name="city" disabled={isCityDisabled || cities.length === 0}>
                                        <option value="" disabled>
                                            {isCityDisabled ? "Wybierz województwo najpierw" : "Wybierz miasto"}
                                        </option>
                                        {cities.map((city) => (
                                            <option key={city} value={city}>
                                                {city}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="city" component="div" style={{color: "red"}} />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="price" type="number" placeholder="Cena" />
                                    <ErrorMessage name="price" component="div" style={{color: "red"}} />

                                    <Field css={inputStyle} name="color" type="text" placeholder="Kolor" />
                                    <ErrorMessage name="color" component="div" style={{color: "red"}} />
                                </FieldWrapperGrid>
                                <FieldWrapper>
                                    <label htmlFor="expiry">Czas oferty (publikacja dni na portalu)</label>
                                    <Field as="select" css={inputStyle} name="expiry" placeholder="Czas oferty">
                                        <option value="" disabled>
                                            Czas oferty na portalu
                                        </option>
                                        <option value="7">7</option>
                                        <option value="14">14</option>
                                        <option value="21">21</option>
                                        <option value="30">30</option>
                                    </Field>
                                    <ErrorMessage name="expiry" component="div" style={{color: "red"}} />
                                </FieldWrapper>

                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="registry_date" type="date" placeholder="Data pierwszej rejestracji" />
                                    <ErrorMessage name="registry_date" component="div" style={{color: "red"}} />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="version" type="text" placeholder="Wersja" />
                                    <Field css={inputStyle} name="generation" type="text" placeholder="Generacja" />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field
                                        css={inputStyle}
                                        name="doors_counts"
                                        type="number"
                                        value={values.doors_counts === 0 ? "" : values.doors_counts}
                                        placeholder="Liczba drzwi"
                                    />
                                    <Field
                                        css={inputStyle}
                                        name="seats_counts"
                                        type="number"
                                        value={values.seats_counts === 0 ? "" : values.seats_counts}
                                        placeholder="Liczba siedzeń"
                                    />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="vin_id" type="text" placeholder="VIN" />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="seller_name" type="text" placeholder="Imię sprzedającego" />
                                    <ErrorMessage name="seller_name" component="div" style={{color: "red"}} />

                                    <Field css={inputStyle} name="phone_number" type="tel" placeholder="Numer kontaktowy" />
                                    <ErrorMessage name="phone_number" component="div" style={{color: "red"}} />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <label css={{display: "flex", alignItems: "center", gap: "8px"}}>
                                        Importowany <Field type="checkbox" name="imported" css={{marginLeft: "8px"}} />
                                    </label>
                                    <label css={{display: "flex", alignItems: "center", gap: "8px"}}>
                                        Uszkodzony <Field type="checkbox" name="damaged" css={{marginLeft: "8px"}} />
                                    </label>
                                </FieldWrapperGrid>
                                {/* Multimedia */}
                                <MultimediaPartForm name="multimedia" values={values} />
                                <ComfortAndConveniencePartForm name="comfort_and_convenience" values={values} />
                                <AppearancePartForm name="appearance_and_materials" values={values} />
                                <SaftyPartForm name="safety" values={values} />
                                <AdditionalFeaturesPartForm name="additional_features" values={values} />
                                <>
                                    <UploadImageToS3WithNativeSdk />
                                    <ErrorMessage name="fileKeys" component="div" style={{color: "red"}} />
                                </>
                                <div>
                                    <Button type="submit" size="lg" variant="primary" disabled={isSubmitting}>
                                        Dodaj ogłoszenie
                                    </Button>
                                </div>
                            </StyledForm>
                        </FormWrapper>
                    );
                }}
            </Formik>
            {alert.message && <Alert type={alert.type} message={alert.message} />}
        </>
    );
};
//TODO this style used in many forms add Component

// const previewWrapper = css`
//     display: inline-block;
//     margin: 10px;
//     &:nth-child(1) {
//         margin-left: 0;
//     }
// `;
//
// const previewImage = (theme: Theme, mainImage: boolean) => css`
//     width: 150px;
//     height: 150px;
//     object-fit: cover;
//     border: ${mainImage ? `2px solid ${theme.colors.brand_primary}` : "2px solid lightgray"};
// `;
// const mainImageStyle = (theme: Theme) => css`
//     color: ${theme.colors.brand_primary};
//     margin: 0;
//     font-weight: bold;
// `;

const inputStyle = css`
    border: 1px solid #a7a7ab;
    padding-block: 10px;
    border-radius: 8px;
    padding-left: 0.6rem;
    background: #ffffff;
    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        border-color: ${carsTheme.colors.brand_primary};
        outline: none;
    }

    &::placeholder {
        padding-left: 0.6rem;
    }
`;

const FormWrapper = styled.div`
    width: 100%;
    //max-width: 400px;
    padding: 40px 0;

    background: #fff;
    z-index: 10;
    position: relative;
`;

const FieldWrapperGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1rem;
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const FieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
        font-size: 14px;
        font-weight: bold;
    }
`;
