import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {CarBrand} from "./CarBrand";

import audi from "../../../assets/popular_models/compressed/audi2.jpg";
import bmw from "../../../assets/popular_models/compressed/bmw.jpg";
import citroen from "../../../assets/popular_models/compressed/citroen.jpg";
import ford from "../../../assets/popular_models/compressed/ford.jpg";
import mercedesBenz from "../../../assets/popular_models/compressed/mercedes.jpg";
import peugeot from "../../../assets/popular_models/compressed/peugeot.jpg";
import renault from "../../../assets/popular_models/compressed/renault.jpg";
import skoda from "../../../assets/popular_models/compressed/skoda.jpg";
import toyota from "../../../assets/popular_models/compressed/toyota.jpg";
import volkswagen from "../../../assets/popular_models/compressed/volkswagen.jpg";
import volvo from "../../../assets/popular_models/compressed/volvo.jpg";
import heroImg from "../../styles/assets/background/car-hero.jpg";

// Creating the popular car brands array with imported images
const popularCarBrands = [
    {value: "volkswagen", name: "Volkswagen", image: volkswagen},
    {value: "volvo", name: "Volvo", image: volvo},
    {value: "audi", name: "Audi", image: audi},
    {value: "bmw", name: "BMW", image: bmw},
    {value: "toyota", name: "Toyota", image: toyota},
    {value: "skoda", name: "Skoda", image: skoda},
    {value: "peugeot", name: "Peugeot", image: peugeot},
    {value: "citroen", name: "Citroen", image: citroen},
    {value: "ford", name: "Ford", image: ford},
    {value: "mercedesbenz", name: "Mercedes-Benz", image: mercedesBenz}
];
export const CarBrandsSection: React.FC = () => {
    return (
        <section css={padding}>
            <Container>
                <h2 css={h2}>Najpopularniejsze marki samochodów</h2>
                <div css={carBrandsGrid}>
                    {popularCarBrands.map((brand) => (
                        <CarBrand key={brand.value} name={brand.name} slug={brand.value} image={brand.image} />
                    ))}
                </div>
            </Container>
        </section>
    );
};

const padding = css`
    padding: 20px;
`;

const h2 = css`
    text-align: center;
    margin-bottom: 20px;
`;
const carBrandsGrid = (theme: Theme) => css`
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    margin-bottom: 2rem;

    @media (min-width: ${theme.breakpoints.screen_xxs}) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: ${theme.breakpoints.screen_xs}) {
        grid-template-columns: repeat(3, 1fr);
        & > *:nth-last-child(1) {
            grid-column: 2 / 3;
        }
    }
    @media (min-width: ${theme.breakpoints.screen_md}) {
        grid-template-columns: repeat(4, 1fr);
        & > *:nth-last-child(2),
        & > *:nth-last-child(1) {
            grid-column: span 2;
            justify-self: center;
        }
    }
    @media (min-width: ${theme.breakpoints.screen_lg}) {
        grid-template-columns: repeat(5, 1fr);
        & > *:nth-last-child(2),
        & > *:nth-last-child(1) {
            grid-column: span 1;
            justify-self: center;
        }
    }
`;
export const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
`;
