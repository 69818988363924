import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {Field} from "formik";

import {IStore} from "../../app/reducers/hybrid_reducer";

const S3_BUCKET = "s3-zakupauto-service";
const REGION = "eu-central-1";

interface UploadResponse {
    url: string;
    filename: string;
}

const EditUploadImageToS3 = () => {
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [uploadProgress, setUploadProgress] = useState<number[]>([]);
    const {values, setFieldValue, submitForm} = useFormikContext<{filekeys: string}>();
    const [fileKeys, setFileKeys] = useState<string[]>(values.filekeys?.split("|"));
    const userData = useSelector((store: IStore) => store.dashboard.userData);
    const [deletedFile, setDeletedFile] = useState<string | null>(null); // Stan do śledzenia usuniętego pliku
    // Pobierz obrazy z S3 na podstawie fileKeys

    useEffect(() => {
        if (values.filekeys) {
            const fileKeysArray = values.filekeys.split("|");

            setFileKeys(fileKeysArray); // Ustawienie fileKeys

            const urls = fileKeysArray.filter((key) => key !== "").map((key) => `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${key}`);

            setImageUrls(urls);
        }
    }, [values.filekeys]);

    const uploadFile = async (file: File) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("userID", userData.data.user_id?.toString() || "");

        try {
            const response = await fetch("https://rw-web-api.eu/wp-json/wp/v2/upload_gallery_to_s3/", {
                method: "POST",
                body: formData
            });

            const data: UploadResponse = await response.json();

            if (response.ok) {
                setImageUrls((prevUrls) => [...prevUrls, data.filename]);

                setFileKeys((prevKeys) => {
                    const updatedKeys = [...prevKeys, data.filename];
                    // setFieldValue("filekeys", updatedKeys.join("|"));

                    return updatedKeys;
                });
            } else {
                console.error("Error uploading file:", data);
            }
        } catch (error) {
            console.warn("err on uploadToS3", error);
        }
    };

    const handleRemoveImage = async (key: string) => {
        const formData = new FormData();
        formData.append("key", key);
        try {
            const response = await fetch("https://rw-web-api.eu/wp-json/wp/v2/delete_from_s3/", {
                method: "POST",
                body: formData
            });

            const data: any = await response.json();

            if (response.ok) {
                setImageUrls((prevUrls) => {
                    return prevUrls.filter((url) => url !== key);
                });

                setFileKeys((prevKeys) => {
                    return prevKeys.filter((fileKey) => fileKey !== key);
                });
                setDeletedFile(key);
            } else {
                console.error("Error deleting file:", data);
            }
        } catch (error) {
            console.warn("err on delete from s3 front ", error);
        }
    };

    useEffect(() => {
        // Gdy fileKeys się zmienia, zaktualizuj filekeys w Formiku
        setFieldValue("filekeys", fileKeys?.join("|"));

        if (deletedFile) {
            const timer = setTimeout(() => {
                submitForm();
            }, 300); // Dodanie debounce (300ms)

            return () => clearTimeout(timer);
        }
    }, [fileKeys, deletedFile, setFieldValue, submitForm]);

    const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const fileArray = Array.from(files);
            fileArray.forEach((file) => uploadFile(file));
        }
    };

    return (
        <div>
            <input type="file" multiple onChange={handleFileInput} />
            <div style={{display: "flex", flexWrap: "wrap", marginTop: "20px"}}>
                {imageUrls.map((url, index) => {
                    const imageKey = url.split("/").pop(); // Extract the file key from the URL
                    if (!imageKey) {
                        return;
                    }
                    const src = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${imageKey}`;

                    return (
                        <div key={url} style={{margin: "10px", textAlign: "center"}}>
                            <img
                                src={src}
                                alt="Preview"
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    marginBottom: "10px"
                                }}
                            />
                            {uploadProgress[index] > 0 && (
                                <progress
                                    value={uploadProgress[index]}
                                    max={100}
                                    style={{
                                        width: "100%",
                                        height: "10px",
                                        borderRadius: "5px",
                                        backgroundColor: "#f3f3f3",
                                        appearance: "none"
                                    }}
                                />
                            )}
                            <button
                                type="button"
                                onClick={() => handleRemoveImage(imageKey)}
                                style={{
                                    display: "block",
                                    marginTop: "5px",
                                    backgroundColor: "red",
                                    color: "white"
                                }}
                            >
                                Usuń
                            </button>
                        </div>
                    );
                })}
            </div>
            <Field type="hidden" name="filekeys" value={values.filekeys} />
        </div>
    );
};

export default EditUploadImageToS3;
