import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {Button} from "@rw/button";
import {TextEditor} from "@rw/editor";
import {carsTheme} from "@rw/themes";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

import {Alert, AlertType} from "../../app/components/alert/Alert";
import {useAlert} from "../../app/components/alert/useAlert";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {apiLink} from "../../app/routes/api_link";
import {Voivodeship, voivodeshipNames} from "../../app/types/locationTypes";
import {postRequest} from "../../app/utils/request_response_utils/request";
import EditUploadImageToS3 from "../../aws/s3/edit_to_s3";
import {carBodyTypes, engineTypes} from "../add_car/options/select_options";
import {AdditionalFeaturesPartForm} from "../components/Additionalfeatures";
import {AppearancePartForm} from "../components/Appearance";
import {ComfortAndConveniencePartForm} from "../components/ComfortAndConvenience";
import {MultimediaPartForm} from "../components/Multimedia";
import {SaftyPartForm} from "../components/SaftyOpt";

interface EditCarFormValues {
    title: string;
    content: string;
    //  expiry: number;
    make: string;
    model: string;
    year: string;
    millage: string;
    engine: string;
    engine_size: string;
    power: number | null;
    price: string;
    gearbox: string;
    color: string;
    voivodeship: string | null;
    city: string | null;
    filekeys?: string;
    registry_date: string;
    seller_name: string;
    phone_number: string;
    version: string;
    generation: string;
    doors_counts: number;
    seats_counts: number;
    vin_id: string;
    body_type: string;
    damaged: boolean;
    imported: boolean;
    multimedia: string[];
    comfort_and_convenience: string[];
    appearance_and_materials: string[];
    safety: string[];
    additional_features: string[];
}
//TODO change expiry to lower when more data available

const editCarValidationSchema = Yup.object({
    title: Yup.string().required("Tytuł jest wymagany")
});

interface IAddCarProps {
    user: {
        id: number | null;
    };
    nonce: string | null;
}

export const EditCar = (props: IAddCarProps) => {
    const {alert, showAlert} = useAlert();
    const fetchedCarData = useSelector((store: IStore) => store.editOfferData.carEditData);

    const handleSubmit = async (values: EditCarFormValues, {resetForm}: {resetForm: () => void}) => {
        try {
            const data = {
                ...values,
                author_id: props.user.id || 1,
                // expiry: values.expiry,
                millage: parseInt(values.millage.replace(" ", "")),
                engine_size: parseInt(values.engine_size.replace(" ", "")),
                filekeys: values.filekeys
            };

            const url = apiLink.editCar({})({...data, offer_id: fetchedCarData.id});

            return postRequest({}, url, {...data, offer_id: fetchedCarData.id})
                .then((res: any) => {
                    showAlert(`Ogłoszenie zaktualizowano.`, AlertType.success, 5000);
                    return res;
                })
                .catch((err) => {
                    console.warn("err on reset", err);
                });
        } catch (err) {
            console.error("Błąd podczas aktualizacji ogłoszenia:", err);
            showAlert(`Błąd podczas aktualizacji ogłoszenia`, AlertType.danger, 5000);
        }
    };

    const formatContentForEditor = (content: string): string => {
        return content.replace(/\r\n|\n|\r/g, "<br />");
    };

    return (
        <>
            <Formik<EditCarFormValues> initialValues={fetchedCarData} validationSchema={editCarValidationSchema} onSubmit={handleSubmit}>
                {({isSubmitting, errors, touched, values, setFieldValue}) => {
                    return (
                        <FormWrapper>
                            <h2 css={{margin: "2rem 0"}}>Edytuj Ogłoszenie </h2>
                            <StyledForm encType="multipart/form-data">
                                <Field css={inputStyle} name="title" type="text" placeholder="Tytuł" required />
                                {errors.title && touched.title && errors.title}
                                <TextEditor
                                    id="content"
                                    name="content"
                                    value={formatContentForEditor(values.content)}
                                    onChange={(name, value) => setFieldValue("content", value)}
                                    error={touched.content && errors.content ? errors.content : null}
                                    maxLength={2000}
                                />
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="make" type="text" placeholder="Marka" required />
                                    <Field css={inputStyle} name="model" type="text" placeholder="Model" required />
                                    <Field as="select" css={inputStyle} name="body_type" placeholder="Typ nadwozia" required>
                                        <option value="" disabled>
                                            Typ nadwozia
                                        </option>
                                        {carBodyTypes.map((bodyType) => (
                                            <option key={bodyType.value} value={bodyType.value}>
                                                {bodyType.label}
                                            </option>
                                        ))}
                                    </Field>
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="year" type="text" placeholder="Rocznik" required />
                                    <Field css={inputStyle} name="millage" type="text" placeholder="Przebieg" required />
                                    <Field
                                        css={inputStyle}
                                        name="power"
                                        type="number"
                                        placeholder="Moc (KM)"
                                        value={values.power === 0 ? "" : values.power}
                                        required
                                    />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field as="select" css={inputStyle} name="engine" placeholder="Silnik" required>
                                        <option value="" disabled>
                                            Wybierz silnik
                                        </option>
                                        {engineTypes.map((engine) => (
                                            <option key={engine.value} value={engine.value}>
                                                {engine.label}
                                            </option>
                                        ))}
                                    </Field>

                                    <Field css={inputStyle} name="engine_size" type="text" placeholder="Pojemność silnika" required />
                                    <Field as="select" css={inputStyle} name="gearbox" placeholder="Skrzynia biegów" required>
                                        <option value="" disabled>
                                            Skrzynia biegów
                                        </option>
                                        <option value="manualna">Manualna</option>
                                        <option value="automatyczna">Automatyczna</option>
                                    </Field>
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field as="select" disabled css={inputStyle} name="voivodeship" placeholder="Województwo">
                                        <option value="" disabled>
                                            Wybierz województwo
                                        </option>
                                        {Object.entries(Voivodeship).map(([key, value]) => (
                                            <option key={key} value={key.toLowerCase()}>
                                                {voivodeshipNames[value]}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="voivodeship" component="div" style={{color: "red"}} />
                                    <Field css={inputStyle} name="city" disabled type="text" placeholder="Miasto" />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="price" type="number" placeholder="Cena" required />
                                    <Field css={inputStyle} name="color" type="text" placeholder="Kolor" required />
                                </FieldWrapperGrid>

                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="registry_date" type="date" placeholder="Data pierwszej rejestracji" />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="version" type="text" placeholder="Wersja" />
                                    <Field css={inputStyle} name="generation" type="text" placeholder="Generacja" />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field
                                        css={inputStyle}
                                        name="doors_counts"
                                        type="number"
                                        value={values.doors_counts === 0 ? "" : values.doors_counts}
                                        placeholder="Liczba drzwi"
                                    />
                                    <Field
                                        css={inputStyle}
                                        name="seats_counts"
                                        type="number"
                                        value={values.seats_counts === 0 ? "" : values.seats_counts}
                                        placeholder="Liczba siedzeń"
                                    />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="vin_id" type="text" placeholder="VIN" />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="seller_name" type="text" placeholder="Imię sprzedającego" />
                                    <Field css={inputStyle} name="phone_number" type="tel" placeholder="Numer kontaktowy" />
                                    {errors.phone_number && touched.phone_number && errors.phone_number}
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <label css={{display: "flex", alignItems: "center", gap: "8px"}}>
                                        Importowany <Field type="checkbox" name="imported" css={{marginLeft: "8px"}} />
                                    </label>
                                    <label css={{display: "flex", alignItems: "center", gap: "8px"}}>
                                        Uszkodzony <Field type="checkbox" name="damaged" css={{marginLeft: "8px"}} />
                                    </label>
                                </FieldWrapperGrid>
                                <MultimediaPartForm name="multimedia" values={values} />
                                <ComfortAndConveniencePartForm name="comfort_and_convenience" values={values} />
                                <AppearancePartForm name="appearance_and_materials" values={values} />
                                <SaftyPartForm name="safety" values={values} />
                                <AdditionalFeaturesPartForm name="additional_features" values={values} />

                                <>
                                    <EditUploadImageToS3 />
                                </>
                                <div>
                                    <Button type="submit" size="lg" variant="primary" disabled={isSubmitting}>
                                        Edytuj ogłoszenie
                                    </Button>
                                </div>
                            </StyledForm>
                        </FormWrapper>
                    );
                }}
            </Formik>
            {alert.message && <Alert type={alert.type} message={alert.message} />}
        </>
    );
};
//TODO this style used in many forms add Component

const previewWrapper = css`
    display: inline-block;
    margin: 10px;
    &:nth-child(1) {
        margin-left: 0;
    }
`;

const previewImage = (theme: Theme, mainImage: boolean) => css`
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: ${mainImage ? `2px solid ${theme.colors.brand_primary}` : "2px solid lightgray"};
`;
const mainImageStyle = (theme: Theme) => css`
    color: ${theme.colors.brand_primary};
    margin: 0;
    font-weight: bold;
`;

const inputStyle = css`
    border: 1px solid #a7a7ab;
    padding-block: 10px;
    border-radius: 8px;
    padding-left: 0.6rem;
    background: #ffffff;
    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        border-color: ${carsTheme.colors.brand_primary};
        outline: none;
    }

    &::placeholder {
        padding-left: 0.6rem;
    }
`;

const FormWrapper = styled.div`
    width: 100%;
    //max-width: 400px;
    padding: 40px 0;

    background: #fff;
    z-index: 10;
    position: relative;
`;

const FieldWrapperGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1rem;
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const FieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
        font-size: 14px;
        font-weight: bold;
    }
`;
const containerStyle = css`
    padding: 4rem;
`;
